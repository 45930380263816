import { borderRadius, hoverRadius } from 'config/theme/borderRadius';

import CmsBlocks from 'libs/wordpress/content/CmsBlocks';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { media } from 'utils/mediaqueries';
import { mediaQueries } from 'config/theme/breakpoints';
import moduleSpacing from 'config/theme/moduleSpacing';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';

const InnerWrapper = styled('div')`
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: border-radius ${transitions.primary};

    ${media.hover} {
        :hover {
            border-radius: ${hoverRadius};
        }
    }
`;

const ColumnsTwoFullscreenModule = ({ content = [], ...rest }) => {
    if (content?.length !== 2) {
        return null;
    }

    return (
        <MaxWidthWrapper includeContentSpacing as="section" py={moduleSpacing.md}>
            <ColumnsLayout
                blocks={content.map((column, index) => (
                    <InnerWrapper borderRadius={borderRadius} aspectRatio="1" key={`${column.layout}-${index}`}>
                        <CmsBlocks isColumn data={column} mediaQuery={mediaQueries.twoColumn} {...rest} />
                    </InnerWrapper>
                ))}
                columnSizes={[12, null, null, null, 6]}
                gutterHeight={12}
                gutterWidth={[0, null, 16, null, 24]}
                style={{ height: '100%' }}
            />
        </MaxWidthWrapper>
    );
};

ColumnsTwoFullscreenModule.propTypes = {
    content: PropTypes.array,
};

export default ColumnsTwoFullscreenModule;
